/* src/styles/Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(50, 48, 49, 0);
  padding: 10px 20px;
  font-family: system-ui;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  box-sizing: border-box;
}

.navbar-logo a {
  color: #FFFFFF;
  font-size: 1.5rem;
  text-decoration: none;
  font-family: system-ui;
  font-weight: bold;
}

.navbar-links {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin-left: 20px;
}

.navbar-links a {
  color: #FFFFFF;
  text-decoration: none;
  font-size: 1.25rem;
  font-family: system-ui;
  font-weight: bold;
}

.navbar-links a:hover {
  color: #dddbcb;
}

.navbar.other-page .navbar-links a {
  color: #ffffff;
}

.navbar.other-page .navbar-logo a {
  color: #ffffff;
}

/* Responsive adjustments: stack links vertically on mobile */
@media (max-width: 768px) {
.navbar {
  flex-direction: column;
  align-items: flex-start;
}

.navbar-links {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
}

.navbar-links li {
  margin-left: 0;
  margin-bottom: 10px;
}

.navbar-links a {
  font-size: 1rem;
}

.navbar-logo a {
  font-size: 1.2rem;
}
}
