/* src/styles/About.css */

/* Ensure html and body take full height */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.about-page {
  min-height: 100vh;
  background: #1e1e1e; /* Dark neutral background for dark mode */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
  color: #ffffff;
  position: relative; /* To contain the absolutely positioned .background */
  z-index: 1; /* Ensure content is above the background */
}

.background {
  position: fixed; /* Fix the background to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Cover the entire viewport */
  z-index: -1; /* Place it behind all other content */
}

.about-container {
  max-width: 900px;
  width: 100%;
  text-align: center;
  z-index: 1;
}

.about-photo {
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 2px solid #118dff;
  box-shadow: 0 4px 10px rgba(0,0,0,0.5);
  z-index: 1;
}

.about-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #ffffff; /* Highlighting section title */
  border-bottom: 2px solid #118dff;
  display: inline-block;
  padding-bottom: 10px;
  z-index: 1;
}

.about-content h3 {
  font-size: 1.8rem;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #ffffff;
  border-bottom: 2px solid #118dff;
  display: inline-block;
  padding-bottom: 10px;
  z-index: 1;
}

.about-content p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  line-height: 1.7;
  z-index: 1;
}

strong {
  color: #3035f7; /* Bold text highlighted in blue */
}

.about-content a {
  color: #ffffff;
  text-decoration: underline;
  word-wrap: break-word;
}

.about-content a:hover {
  text-decoration: none;
}