/* src/styles/BackgroundVideo.css */

.home {
    position: relative;
    height: calc(100vh - 80px); /* Adjust based on navbar height */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    overflow: hidden; /* Prevents the video from overflowing */
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the entire area without distortion */
    z-index: -1; /* Places the video behind the content */
}

.home-content {
    position: relative; /* Ensures content is above the video */
    text-align: center;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 5%; /* Changed from 50px for responsiveness */
    border-radius: 10px;
    z-index: 1; /* Ensures content stays above the video */
    max-width: 90%; /* Prevents overflow */
}
