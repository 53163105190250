/* src/styles/SalaryAnalysis.css */
.salary-analysis {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #1e1e1e; /* Dark background */
  color: #ffffff;
  height: 100vh;
  text-align: center;
  padding: 0 20px;
  font-family: Arial, sans-serif;
}

.salary-analysis h1 {
  font-size: 3em;
  margin-bottom: 20px;
  color: #ffffff; /* Use accent color for main title */
  border-bottom: 2px solid #118dff;
  padding-bottom: 10px;
  z-index: 1;
}

.salary-analysis p {
  font-size: 1.5em;
  margin-bottom: 40px;
  color: #ffffff;
  max-width: 600px;
  z-index: 1;
}

.cta-button {
  background-color: #118dff;
  color: #ffffff;
  padding: 15px 30px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #1e25b5;
}

html, body {
  margin: 0;
  padding: 0;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
  pointer-events: none; /* Add this line */
}